/* GENERIC */
body {
  font-weight: 600;
}

h1 {
  font-size: 40px;
  color: #f3702d;
}

.dox_h1 {
  font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: rgba(59, 69, 78, 1);
}

.dox_h3 {
  font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  font-family: gitbook-content-font, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  color: rgba(59, 69, 78, 1);
}

h2 {
  color: #3f4551;
  text-align: center;
  margin: 50px;
}

p {
  color: #6d2e03;
  font-size: 14px;
  line-height: 2;
  font-weight: 400px;
  text-align: "left";
}

table th {
  background: rgb(16, 16, 16);
  color: white;
  padding: 6px;
  text-align: left;
  border: none;
  font-size: 12px;
}

td {
  text-align: left;
  font-size: 12px !important;
  font-weight: 400 !important;
  vertical-align: middle !important;
  word-break: break-all;
  padding: 1px 1px 1px 20px !important;
}

tbody:hover tr:hover td {
  color: #197fc2;
}

tbody > tr:hover #cell_link {
  color: #197fc2 !important;
}

.search-label {
  width: 25%;
  text-align: center;
  display: inline-block;
  margin: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

.segment {
  background: none !important;
  border: 0px !important;
  border-radius: 0px !important;
  border-bottom: 0px !important;
  box-shadow: none !important;
}

ul {
  list-style: none;
  padding: 5px;
}

.page_header {
  margin: 20px 20px 20px 20px;
  display: inline-flex;
  width: 95%;
  color: #f3702d;
  height: 30px;
}

.page_header > h2 {
  font-weight: bold;
  text-align: center;
  color: #f3702d;
  margin-right: 20px;
}

.spinner {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.spinner span {
  font-size: 2rem;
  animation: fade 1s linear 0s infinite;
  padding-right: 1rem;
}

.half-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgb(6, 185, 6);
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 0.5s linear 0s infinite;
}

.tel_span {
  padding: 6px 6px 6px 12px;
  border-radius: 5px;
  margin: 0 auto;
  background-color: rgb(251, 251, 251);
  border: 1px solid #f3702d;
  display: inline-block;
  line-height: 20px !important;
  width: 43%;
  margin-right: 15px;
  font-size: 14px;
  margin-bottom: 15px;
}

.info_descr {
  width: 200px;
  font-size: 14px;
  float: left;
}

.info_span {
  padding: 6px 6px 6px 12px;
  border-radius: 5px;
  margin: 0 auto;
  background-color: rgb(251, 251, 251);
  border: 1px solid #f3702d;
  display: inline-block;
  line-height: 20px !important;
  width: 65%;
  font-size: 14px;
  margin-bottom: 5px;
}

label {
  vertical-align: middle;
}

.card {
  margin: 10px;
}

.card-header {
  font-weight: bold;
}

.lat_lng_span {
  padding: 6px 6px 6px 12px;
  border-radius: 5px;
  margin: 0 auto;
  background-color: rgb(251, 251, 251);
  border: 1px solid #f3702d;
  display: inline-block;
  line-height: 20px !important;
  width: 268px;
  font-size: 14px;
  margin-bottom: 5px;
}

.tel_details_ul > li {
  display: inline-block !important;
  width: 35%;
  margin-right: 50px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.gallery_img > a > img {
  max-width: 300px !important;
  max-height: 220px !important;
  min-width: 300px !important;
  min-height: 220px !important;
  padding-bottom: 20px;
}

.glr_img_card {
  display: inline;
  margin: 20px;
  max-width: 340px !important;
  max-height: 400px !important;
  min-width: 340px !important;
  min-height: 340px !important;
  text-align: center;
}

.react-tabs__tab-list {
  margin-top: 10px;
  text-align: center;
}

.edit_icon {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  color: rgb(121, 121, 121);
  float: right;
}

.edit_icon:hover {
  cursor: pointer;
  color: black;
}

.modal-backdrop {
  opacity: 0.8 !important;
}

#delete_note_icon {
  float: right;
  color: red;
  font-size: 1.5rem;
}

/* delete note icon on hover */
#delete_note_icon:hover {
  cursor: pointer;
  color: black;
}

/* on change refcode, react component with autocomplete */
#agent_select,
#offer_select,
#user_select,
#client_select {
  width: 93% !important;
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 10px;
}

/* list containing notes*/
#note_ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* for each note */
#note_ul > li {
  text-decoration: none;
  color: #000;
  background: #ffc;
  display: block;
  width: 13em;
  padding: 1em;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  margin: 1em;
}

/* note's header */
.note_header {
  font-size: 14px;
  text-align: left;
  width: 100%;
  margin: 0 auto;
}

/* note's <p> text*/
.note_content {
  font-size: 12px;
  font-weight: normal;
  color: black;
}

/* have different rotation for even numbered note */
#note_ul > li:nth-child(even) {
  transform: rotate(4deg);
  position: relative;
  top: 5px;
}

/* have different rotation for 3rd note */
#note_ul > li:nth-child(3n) {
  transform: rotate(-3deg);
  position: relative;
  top: -5px;
}

/* have different rotation for 5th note */
#note_ul > li:nth-child(5n) {
  transform: rotate(5deg);
  position: relative;
  top: -10px;
}

/* on hover, display it x 1.25 scale */
#note_ul li:hover,
ul li:focus {
  box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
  transform: scale(1.25);
  position: relative;
  z-index: 5;
}

/* green note for even */
#note_ul li:nth-child(even) {
  position: relative;
  top: 5px;
  background: #cfc;
}

/* divisible by 3 = purple */
#note_ul li:nth-child(3n) {
  position: relative;
  top: -5px;
  background: #ccf;
}

#add_terminal_modal,
#add_new_group_document {
  max-height: 52%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ui.small.basic.scrolling.modal.transition.visible.active {
  right: 0 !important;
  top: 20% !important;
  width: 30%;
}

.react-date-picker__wrapper {
  border: none;
}

.conflict_modal {
  position: absolute !important;
  left: 25% !important;
  top: 10% !important;
  z-index: 33000 !important;
}

#repair_shop_icon {
  max-width: 35px;
}

#number-filter-column-year {
  margin-left: 10px;
  width: 60%;
}

.react-search-box-dropdown-list-item {
  height: auto !important;
}

#heatmap_container {
  border: 1px solid grey;
}

#available_box {
  background-color: green;
  border: 1px solid black;
  border-radius: 4px;
  margin: 4px;
  display: inline-block;
  color: white;
  line-height: 2.5;
  word-wrap: break-word;
  font-size: 10px;
  font-weight: 500;
  padding: 0px 4px 0px 4px;
}

#not_available_box {
  background-color: rgb(255, 0, 0);
  border: 1px solid black;
  border-radius: 4px;
  margin: 4px;
  display: inline-block;
  color: white;
  line-height: 2.5;
  word-wrap: break-word;
  font-size: 10px;
  font-weight: 500;
  padding: 0px 4px 0px 4px;
}

#pois_ul {
  columns: 3;
}

#pois_ul > li {
  margin: 0 auto;
}

#pois_ul > li > button {
  width: 250px;
  text-align: left;
  margin-bottom: 10px;
}

.marker:hover {
  cursor: pointer !important;
}

.centered {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/* Select office dropdown */
#officeSelect {
  display: inline-block;
  width: 200px;
  margin-left: 15px;
}

/* Select agent or client */
#agent_client_select {
  display: inline-block;
  width: 200px;
  margin-left: 15px;
}

/* rest of code input */
#rest_of_code_input {
  display: inline-block;
  width: 200px;
  margin-left: 15px;
}

#modal-content > ul > li > div > label {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
}

#modal-content > ul > li {
  padding: 5px;
  margin: 5px;
}

#modal-content > ul > li > label {
  padding-right: 15px;
}

#modal-content > ul > li > input {
  max-width: 300px;
  display: inline;
}

/* Makes cancelled groups text red */
.cnclled {
  color: red !important;
}

#daily_status_map {
  margin-left: 40% !important;
}

#pie_chart_office {
  max-width: 230px !important;
  margin-left: 50px;
}

.pie_chart_ul {
  margin-top: 50px;
}

#pie_chart_office > svg > text {
  opacity: 1 !important;
}

#download_doc_btn {
  float: right;
}

.cell_header {
  color: rgb(255, 0, 0);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 5px;
}

.cell_content {
  color: green;
}

.marker_tooltip {
  background-color: white;
  width: 350px;
  margin-top: -150px;
  margin-left: 35px;
  font-size: 16px;
  z-index: 9;
}

.marker_tooltip > ul {
  margin-left: 20px;
  list-style-type: disc;
}

.cluster-marker {
  width: 24px !important;
  height: 24px !important;
  text-align: center;
  font-weight: bold;
}

.cluster-marker:hover {
  cursor: pointer;
  border-radius: 15px;
  font-size: 13px;
}

#delete_group_document_icon {
  color: red;
  font-size: 1.5rem;
}

#delete_group_document_icon:hover {
  cursor: pointer;
  color: black;
}

#download_group_doc_icon:hover {
  cursor: pointer;
  color: black !important;
}

#download_group_doc_icon {
  width: 50px;
  margin: 0 auto;
}

#delete_doc_modal {
  width: 50px !important;
  margin: 0 auto !important;
}

/* makes semantic-ui's footer top and bottom light grey */
.modal-header,
.modal-footer {
  background-color: rgba(0, 0, 0, 0.03);
}

/* arrival's flight / departure's flight date picker*/
.react_dp_input {
  display: inline-block;
  text-align: center;
  margin-left: 10px;
  line-height: 30px;
  border-radius: 5px;
  border: 1px solid rgb(212, 212, 212);
}

/* arrival's flight / departure's flight date picker*/
.select_airport {
  display: inline-block;
  margin-left: 20px;
}

#edit_icon_rooming_list {
  margin-top: 5px;
  margin-right: 100px;
}

.tox-statusbar {
  visibility: hidden;
}

/* Selected row */
.clicked_row {
  background-color: #a4c7eb !important;
  color: white !important;
}

/* Warning message if dates are not consecutive */
#consecutive_warning_message {
  color: red;
  font-size: 22px;
  width: 100%;
  text-align: center;
  font-weight: 700;
}

.radio_to_include_all_days {
  float: right;
  margin: 15px;
  margin-right: 50px;
  width: 30%;
}

i,
img {
  caret-color: transparent;
}

.add_service_input {
  float: right;
  width: 50% !important;
  margin: 0 auto !important;
}

#stats_grid {
  float: right;
  width: 50%;
}

#map {
  float: right;
  padding: 10px;
  margin: 10px;
  width: 40%;
}

.middle {
  margin: 10px;
  display: flex;
}

.group_stats_tab_label {
  font-size: 15px;
  font-weight: bold;
  color: rgb(100, 100, 100);
  text-align: center;
}

.rdrInputRangeInput {
  width: 50px !important;
}

.pie_chart_label {
  text-align: center;
  font-size: 16px;
  margin: 0 auto;
  font-weight: bold;
  width: 100%;
}

#group_stats_chart {
  width: 60% !important;
  margin: 0 auto;
}

#driver_conflict_content {
  height: 250px !important;
}

.rootContainer {
  min-height: 86vh;
  position: relative; /* establish a positioning context */
}

.rootMenu {
  width: 20% !important;
  margin-left: 40% !important;
  margin-right: 40% !important;
}

.rootMenu > a {
  font-size: 20px;
  border-bottom: 1px solid black !important;
  background-color: rgb(16, 16, 16) !important;
  color: white !important;
  padding: 20px !important;
}

.rootMenu > a:hover {
  background-color: #252525 !important;
  color: rgb(255, 123, 0) !important;
}

.dmRootMenu {
  width: 70% !important;
  margin-left: 15% !important;
  margin-right: 15% !important;
}

.dmRootMenu > a {
  font-size: 20px;
  border-bottom: 1px solid black !important;
  background-color: rgb(16, 16, 16) !important;
  color: white !important;
  padding: 20px !important;
}

.dmRootMenu > a:hover {
  background-color: #252525 !important;
  color: rgb(255, 123, 0) !important;
}

/* Media query for screens with max-width of 768px */
@media screen and (max-width: 990px) {
  .rootMenu {
    /* Setting container's width to 80% for mobile devices */
    width: 80% !important;
    margin: 10% !important;
  }
}

.invalid {
  box-shadow: 0px 0px 6px 3px rgba(255, 0, 0, 0.25) !important;
  border-color: none !important;
}

#delete_contact_person_icon {
  color: red;
  font-size: 1.7em;
  cursor: pointer;
  display: "inline-block";
  margin-top: 6px;
  float: right;
}

#delete_contact_person_icon:hover {
  color: black;
}

#contact_person_ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#add_contact_person_icon {
  color: green;
  font-size: 2em;
  float: right;
}

#add_contact_person_icon:hover {
  font-size: 2em;
  float: right;
  cursor: pointer;
}

.ant-picker-clear {
  visibility: hidden !important;
}

.selected_agent_icon {
  border: 3px solid rgb(30, 24, 102);
  border-radius: 10px;
}
.selected_agent_icon:hover {
  cursor: pointer;
}

.agent_icon:hover {
  cursor: pointer;
}

#arr_dep_icon {
  top: 0 !important;
  left: 0 !important;
  margin-right: 10px;
  font-size: 20px;
  color: red;
}

#arr_dep_span {
  color: red;
  font-size: 12px;
  font-weight: bold;
}

#arr_dep_text_span {
  color: green;
  font-size: 11px;
  font-weight: bold;
}

.leaflet-marker-icon {
  max-width: 30px !important;
  max-height: 30px !important;
}

.react-datepicker__input-container > input {
  border-radius: 6px !important;
  border: 1px solid rgb(201, 201, 201);
  padding: 6px;
  width: 140px;
  display: inline !important;
  margin-right: 10px;
  margin-top: 0px !important;
  text-align: center;
}

.weekdays {
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}

.weekday {
  flex: 1;
  text-align: center;
  font-weight: bold;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
  margin-top: 10px;
}

.day {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-weight: bold;
}

.prev-month,
.current-month {
  background-color: #fff;
}

.prev-month {
  color: #ccc;
}

.current-month {
  color: #333;
}

.day:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.no_allocation_remaining {
  background-color: red;
}

.release_date_passed {
  background-color: #ff7700;
}

.allocation_remaining {
  background-color: #004d00;
}

.sold_out {
  background-color: #ffae00;
}

.limited_availability {
  background-color: #00b300;
}

.room_span {
  padding: 4px 4px 4px 12px;
  border-radius: 5px;
  margin: 0 auto;
  background-color: rgb(251, 251, 251);
  border: 1px solid #f3702d;
  display: inline-block;
  width: 80px;
  font-size: 14px;
  max-height: 30px;
  margin-bottom: 10px;
}

/* Example CSS for styling the react-paginate component */
.react-pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.react-pagination li {
  display: inline-block;
  margin: 2px;
  cursor: pointer;
  padding: 2px 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.react-pagination li.active {
  background-color: #007bff;
  color: white;
}

.react-pagination li.disabled {
  opacity: 0.5;
  cursor: default;
}

.mainContainer {
  min-height: 86vh !important;
}

.notificationBadge {
  background-color: red;
  margin-left: 10px;
  padding: 5px !important;
}

.doc_icon:hover {
  cursor: pointer;
  color: black !important;
}

.download_doc_icon:hover {
  cursor: pointer;
  color: black !important;
}

.user_details_ul {
  columns: 3;
  width: 60%;
  margin: 0 auto;
}

.reports_datepicker_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reports_datepicker {
  border: 1px solid rgb(199, 199, 199);
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 4px;
}

.filter-label {
  display: block;
  width: 100%;
  color: #3370a8;
}

.rmdp-container,
.rmdp-input {
  width: 100% !important;
  height: 100% !important;
}

.reports_li {
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid rgb(196, 196, 196);
  margin: 10px;
}

.reports_li:hover {
  background-color: rgb(238, 238, 238);
}

.bank-icon {
  max-height: 50px;
  margin-left: 30px;
}

#payment_order_table > thead > tr > th {
  font-size: 14px;
}

#payment_order_table > tbody > tr > td {
  font-size: 12px !important;
}

.payment_doc_icon {
  margin: 0 auto;
  border: 0px solid black;
  margin-left: 10px;
}

.payment_doc_icon:hover {
  cursor: pointer !important;
  border: 1px solid #4e4e4e !important;
  border-radius: 6px !important;
  background-color: rgb(247, 247, 247);
}

.show_more_icon:hover {
  color: rgb(2, 61, 117);
  border-radius: 4px !important;
  cursor: pointer;
}

.show_more_icon {
  border-radius: 4px !important;
  margin: 6px;
  font-size: 1.5em;
  color: #06abe0;
}

.icon-hover {
  margin-left: 10px;
}

.icon-hover:hover {
  cursor: pointer !important;
  border: 1px solid #000000 !important;
  border-radius: 6px !important;
  background-color: white;
}

.Select-input > input {
  width: 100% !important;
  border-radius: 4px;
  border: 1px solid rgb(189, 189, 189);
}

.selected_payment {
  background-color: rgb(216, 238, 216);
}

.add_to_payments_icon {
  font-size: 20px;
  float: right;
  border: 1px solid green;
  cursor: pointer;
  border-radius: 4px;
  color: green;
}

.add_to_payments_icon:hover {
  background-color: green;
  color: white !important;
}

.remove_from_payments_icon {
  font-size: 20px;
  float: right;
  border: 1px solid red;
  cursor: pointer;
  border-radius: 4px;
  color: red;
}

.remove_from_payments_icon:hover {
  background-color: red;
  color: white;
}

.suggestion {
  cursor: pointer;
  padding: 10px;
}

#send_mass_mail_modal {
  width: 100% !important;
}

.mass_mail_selected_box {
  background-color: rgb(0, 117, 39);
  color: white;
  font-weight: 500;
}

.grey-powerline {
  width: 98% !important;
  border: none;
  border-top: 0.5px solid rgb(189, 189, 189);
  margin: 10px 10px 10px 10px;
  box-shadow: 0 0.5px 0 rgba(0, 0, 0, 0.1);
}

.plus-icon {
  color: green;
  font-size: 30px;
}

.plus-icon:hover {
  color: rgb(2, 99, 2);
  cursor: pointer;
}

.minus-icon {
  color: red;
  font-size: 30px;
}

.minus-icon:hover {
  color: rgb(99, 17, 2);
  cursor: pointer;
}

.equal-amount {
  color: green;
  text-decoration: line-through;
}

.react-tel-input {
  width: 50% !important;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
}

.coach-image {
  height:  240px !important;
}

#react-tabs-1 > div > div.ui.stackable.divided.two.column.grid > div:nth-child(2) > div > div.card-body > div:nth-child(17) > p:nth-child(3) > img {
  max-width: 400px !important;
}


.prof_descr {
  width: 300px;
  font-size: 14px;
  float: left;
}

.prof_span {
  padding: 6px 6px 6px 12px;
  border-radius: 5px;
  margin: 0 auto;
  background-color: rgb(251, 251, 251);
  border: 1px solid #f3702d;
  display: inline-block;
  line-height: 20px !important;
  width: 90%;
  font-size: 14px;
  margin-bottom: 5px;
}
