.conflict_modal {
  position: absolute;
  top: 22%;
  left: 22%;
}

#tablet_icon:hover {
  background-color: rgb(233, 233, 233) !important;
}

#table_icon:hover {
  background-color: rgb(233, 233, 233) !important;
}

#tablet_icon {
  background-color: white;
}
#table_icon {
  background-color: white;
}

.list-icon {
  font-size: 2em;
  margin-left: 1em;
  color: #e66700;
}

.list-icon:hover {
  cursor: pointer;
  color: #f56f42;
}