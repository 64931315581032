@media print {
  footer * {
    display: none;
  }

  #group_stats_header {
    display: none !important;
  }

  .grid {
    display: none !important;
  }

  hr {
    display: none !important;
  }

  .button {
    display: none !important;
  }

  .breadcrumb {
    display: none !important;
  }
}
