/* Styles for the '.homeContainer' */
.homeContainer {
  background-color: rgba(0, 0, 0, 1);
  color: white;
  text-align: center;
  min-height: 86vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Styles for '.grid' within '.homeContainer' */
.homeContainer > .grid {
  margin-bottom: 10%;
}

/* Styles for headings within '.homeContainer > div > div' */
.homeContainer > div > div > h2 {
  font-size: 72px !important;
  line-height: 76px !important;
  margin-bottom: 16px !important;
  position: relative !important;
  box-sizing: border-box;
  display: block;
  font-weight: 800 !important;
  color: white;
}

/* Styles for paragraphs within '.homeContainer > div > div' */
.homeContainer > div > div > p {
  color: #8193b2 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  font-weight: 400 !important;
  margin-right: -24px !important;
  margin-bottom: 24px !important;
  position: relative !important;
  margin-top: 0;
  display: block;
}

/* Styles for '#globe' */
#globe {
  height: 430px;
  width: 430px;
}

/* Media query targeting viewports with a max width of 768px */
@media screen and (max-width: 768px) {
  /* Adjustments for smaller screens */
  #globe {
    height: 200px;
    width: 200px;
    margin-top: 20px;
  }

  /* Adjustments for headings within smaller screens */
  .homeContainer > div > div > h2 {
    font-size: 42px !important;
    line-height: 42px !important;
  }

  /* Adjustments for paragraphs within smaller screens */
  .homeContainer > div > div > p {
    font-size: 14px !important;
    line-height: 14px !important;
  }
}
