/* Styles for '.loginContainer' */
.loginContainer {
  min-height: 86vh;
}

/* Styles for 'form' element */
form {
  border: 1px solid rgb(206, 206, 206);
  padding: 10px;
  border-radius: 5px;
}

/* Styles for the login error message */
.login-message {
  color: #ff3636;
  font-family: sans-serif;
  font-weight: 500;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
}

/* Styles for the locked icon */
.lockedIcon {
  color: #ff3636;
  font-size: 9em;
  display: block;
  margin: 0 auto;
}

/* Styles for the login button */
.loginButton {
  width: 100%;
  margin: 0 auto;
}

/* Styles for the eye icon when hiding password */
.eyeHide {
  float: right;
  font-size: 18px;
  color: green;
  margin-left: 10px;
  cursor: pointer;
}

/* Styles for the eye icon when showing password */
.eyeShow {
  float: right;
  font-size: 18px;
  color: red;
  margin-left: 10px;
  cursor: pointer;
}

/* Styles for the 'cosmoplanLogo' */
.cosmoplanLogo {
  max-height: 150px;
}
