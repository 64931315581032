.navLogo {
  margin-right: 20px;
  margin-left: 10px;
  width: 190px;
  height: 50px;
}

.nav-link {
  color: white !important;
  margin-left: 10px;
}

.nav-link:hover {
  color: rgb(255, 123, 0) !important;
}

.navbar {
  font-weight: 500 !important;
  min-height: 7vh !important;
  background-color: rgb(16, 16, 16);
}

#nav_group_dropdown {
  background-color: rgb(16, 16, 16) !important;
  width: 170px;
  margin-top: 5px !important;
  z-index: 1001;
}

#nav_group_dropdown > a {
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* group dropdown's links on hover */
#nav_group_dropdown > a:hover {
  color: rgb(255, 123, 0);
  background-color: rgb(16, 16, 16) !important;
}

/* Background color is same as navbar's */
#nav_report_dropdown {
  background-color: rgb(16, 16, 16) !important;
  margin-top: 5px !important;
  margin-left: -230px !important;
  columns: 3;
  z-index: 1001;
}

/* report dropdown's links */
#nav_report_dropdown > a {
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
}

/* report dropdown's links on hover */
#nav_report_dropdown > a:hover {
  color: rgb(255, 123, 0);
  background-color: rgb(16, 16, 16) !important;
}

/* Background color is same as navbar's */
#nav_data_dropdown {
  background-color: rgb(16, 16, 16) !important;
  margin-top: 5px !important;
  margin-left: -300px !important;
  columns: 4;
  z-index: 1001;
}

/* data management dropdown's links */
#nav_data_dropdown > a {
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
}

/* data management dropdown's links on hover */
#nav_data_dropdown > a:hover {
  color: rgb(255, 123, 0);
  background-color: rgb(16, 16, 16) !important;
}

#nav_map_dropdown {
  background-color: rgb(16, 16, 16) !important;
  margin-top: 5px !important;
  margin-left: -330px !important;
  columns: 3;
}

/* data management dropdown's links */
#nav_map_dropdown > a {
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
}

/* data management dropdown's links on hover */
#nav_map_dropdown > a:hover {
  color: rgb(255, 123, 0);
  background-color: rgb(16, 16, 16) !important;
}

/* admin dropdown */
#nav_admin_dropdown {
  background-color: rgb(16, 16, 16) !important;
  margin-top: 5px !important;
  margin-left: 20px !important;
}

/* admin dropdown's links */
#nav_admin_dropdown > a {
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
}

/* admin dropdown's links on hover */
#nav_admin_dropdown > a:hover {
  color: rgb(255, 123, 0);
  background-color: rgb(16, 16, 16) !important;
}

/* me dropdown */
#nav_me_dropdown {
  background-color: rgb(16, 16, 16) !important;
  width: 170px;
  margin-top: 5px !important;
  float: right;
  margin-left: -50px !important;
}

/* me dropdown links */
#nav_me_dropdown > a {
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* me dropdown's links on hover */
#nav_me_dropdown > a:hover {
  color: rgb(255, 123, 0);
  background-color: rgb(16, 16, 16) !important;
}

.auth_button {
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
}

.auth_button:hover {
  color: rgb(255, 123, 0);
}
